@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-snap-type: y mandatory;
}
body {
  font-family: 'Plus Jakarta Sans';
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 16px;
}
